import { Button, Form, Input, InputNumber, Modal, Spin } from "antd";
import * as Api from "../../../../../../apis";
import { useState } from "react";

const AddCategoryForm = ({
    isModalOpen,
    onShow,
    addedCategory
}: {
    isModalOpen: boolean;
    onShow: () => void;
    addedCategory: (value: Api.Category) => void;
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = async (values: { name: string, rate: number }) => {
        setLoading(true);
        const category: Api.CategoryAddRequest = {
            name: values.name,
            rate: values.rate,
        };
       const response =  await Api.addCategory(category);
       addedCategory(response);
       setLoading(false);
        onShow();
    };

return(
    <Modal
    forceRender
    getContainer={false}
    title="Додати категорію"
    open={isModalOpen}
    onCancel={onShow}
    footer={[]}
>
    <Form
        form={form}
        name="AddProductForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        autoComplete="off"
    >
        <Form.Item
            label="Ім'я"
            name="name"
            rules={[
                {
                    required: true,
                    message: "Ім'я є обов'язковим!"
                },
            ]}
        >
            <Input />
        </Form.Item>
       
    <Form.Item label="Рейтинг" valuePropName="rate" name="rate">
      <InputNumber defaultValue={0} />
    </Form.Item>
        <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
            <Button type="primary" htmlType="submit">
                Додати
            </Button>
        </Form.Item>
    </Form>
    <Spin spinning={loading} fullscreen />
</Modal>
);
}

export default AddCategoryForm;