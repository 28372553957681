import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  FacebookFilled,
  InstagramFilled,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Space } from "antd";
import "./footer.scss";
import * as Api from "../../../../apis";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface FooterProps {
  categories: Api.Category[];
}

const Footer: React.FC<FooterProps> = ({ categories }) => {
  const navigate = useNavigate();

  const handleClickCategory = (id: number) => {
    scrollToTop();
    navigate(`/category/${id}`);
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer-main">
      <div className="custom-container">
        <Row className="footer-container">
          <Col xs={24} sm={12} className="footer-left-2">
            <div className="footer-about">
              <h1>Оксамит Волинський</h1>
              <p>Кондитерські вироби від виробника</p>
            </div>
          </Col>

          <Col xs={24} sm={12} className="footer-right-2">
            <Row className="footer-link-2">
              <Col sm={8} xs={24} className="footer-link__wrapper">
                <div className="footer-title-2">ПОСИЛАННЯ</div>
                <ul className="footer-link-2__list">
                  <li>
                    <a href="about">Контакти</a>
                  </li>
                  <li>
                    <a href="contact">Про нас</a>
                  </li>
                </ul>
              </Col>
              <Col sm={8} xs={24} className="footer-link__wrapper">
                <div className="footer-title-2">КАТЕГОРІЇ</div>
                <ul className="footer-link-2__list">
                  {categories.map((category: Api.Category, index: number) => {
                    return (
                      <li key={index}>
                        <a
                          onClick={() => {
                            handleClickCategory(category.id);
                          }}
                        >
                          {category.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col sm={8} xs={24} className="footer-link__wrapper">
                <div className="footer-title-2">КОНТАКТИ</div>
                <ul className="footer-link-2__list">
                  <li>
                    <a href="mailto: oksamyt.volynskii@urk.net">oksamyt.volynskii@urk.net</a>
                  </li>
                  <li>
                    <a href="tel:+380962788588">+380962788588</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="footer-copyright-2">
          <Row style={{ textAlign: "center" }}>
            <Col span={12} xs={24}>
              <div style={{ textAlign: "center" }}>
                <p>
                  ©<span className="current-year">2024</span>
                  <span> ОКСАМИТ ВОЛИНСЬКИЙ </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
