import { LogoutOutlined, ProductOutlined, UnorderedListOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, ConfigProvider, Layout, Menu, Popconfirm, Space, Spin, Table, TableProps, Tag, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Api from "../../../../apis";
import './adminDashboard.scss';
import { ColumnsType } from 'antd/es/table';
import { ToastContainer, Zoom } from 'react-toastify';
import AddProductForm from './forms/addProduct/AddProductForm';
import UpdateProductForm from './forms/updateProduct/UpdateProductForm';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import ProductAdmin from './products/ProductAdmin';
import CategoryAdmin from './categories/CategoryAdmin';


const AdminDashboard = () => {
   
  const tokenAdmin = localStorage.getItem('token')
    const { Header, Content, Footer } = Layout;
    const navigate = useNavigate();
    const [numberMenu, setNumberMenu] = useState<number>(0);
      const items = [
        {
            key: 0,
            label: <div><ProductOutlined /> Продукти </div>
        },
        {
            key: 1,
            label: <div><UnorderedListOutlined /> Категорії </div>
        },
        {
            key: 2,
            label: <a onClick={()=>{
              localStorage.setItem('token', '');
              navigate('/');

            }}><LogoutOutlined /> Вийти</a>
        }
      ];
    
  return (
    <div>
    {tokenAdmin &&
    <ConfigProvider
    theme={{
      token:{
          colorPrimary: '#d68d67',
          colorPrimaryActive: '#d68d67',
          colorPrimaryHover: '#d68d67',
          fontFamily: 'Andika'
      },
      components: {
        Menu: {
          horizontalItemSelectedColor: "none",
          colorSplit: "none",
          horizontalItemHoverColor: '#d68d67',
          itemSelectedColor: '#d68d67'
        },
      
      },
    }}
  >
    <Layout style={{position: 'relative', minHeight: '100vh'}} >
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="demo-logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        items={items}
        defaultSelectedKeys={['0']}
        onClick={(e)=>{
            setNumberMenu(Number(e.key))
        }}
        style={{ flex: 1, minWidth: 0 }}
      />
    </Header>
    {numberMenu == 0 && <ProductAdmin/>}
    {numberMenu == 1 && <CategoryAdmin/>}
   
    <Footer style={{ textAlign: 'center', position: 'absolute', bottom: '0', width: '100%'}}>
      Оксамит Волинський ©{new Date().getFullYear()} 
    </Footer>
  
  </Layout>
  </ConfigProvider>
      }
      </div>
  );
  
}

export default AdminDashboard;