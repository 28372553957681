import React, { useEffect, useState } from "react";
import "./header.scss";
import {
  Badge,
  Button,
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Image,
  Input,
  Menu,
  MenuProps,
  Modal,
  Popover,
  Row,
  Spin,
} from "antd";
import logo_st from "../../../../assets/images/header-logo.png";
import st from "../../../../assets/images/285.jpg";


import {
  DownOutlined,
  HomeFilled,
  MailFilled,
  MenuOutlined,
  PhoneFilled,
  PhoneOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import * as Api from "../../../../apis";

const Header = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Api.Category[]>([]);
  const { Search } = Input;
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const delayed = async () => {
      var resultCategories = await Api.getAllCategory();
      setCategories(resultCategories);
    };
    delayed();
  }, []);

  const convertToMenuItems = (categories: Api.Category[]) => {
    return categories.map((category) => ({
      label:  <div className="menu-item-title">{category.name}</div>,
      key: `${category.id}`,
    }));
  };

  const onClickMenu: MenuProps["onClick"] = (e) => {
    if(e.key === 'SubMenu2'){
      navigate(`/about`);
    }
    if(e.key === 'SubMenu3'){
      navigate(`/contact`);
    }
    if (!isNaN(parseFloat(e.key)) && isFinite(parseFloat(e.key))) {
      navigate(`/category/${e.key}`);
    }
    onClose();
  };

  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const showModal = () => {
    if (token) {
      navigate(`/admin`);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="menu-item-title">
          Категорії <DownOutlined style={{ fontSize: 10 }} />
        </div>
      ),
      key: "SubMenu1",
      children: convertToMenuItems(categories),
    },
    {
      label: <div className="menu-item-title">Про нас</div>,
      key: "SubMenu2",
    },
    {
      label: <div className="menu-item-title">Контакти</div>,
      key: "SubMenu3",
    },
  ];

  const itemsMobile: MenuProps["items"] = [
    {
      label: (
        <div className="menu-item-title">
          Категорії
        </div>
      ),
      key: "SubMenu1",
      children: convertToMenuItems(categories)
    },
    {
      label: <div className="menu-item-title">Про нас</div>,
      key: "SubMenu2",
    },
    {
      label: <div className="menu-item-title">Контакти</div>,
      key: "SubMenu3",
    },
  ];
  
  const onFinish = async (values: { login: string; password: string }) => {
    setLoading(true);
    const response = await Api.loginUser(values.login, values.password);
    setLoading(false);
    if (response) {
      navigate(`/admin`);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="header">
      <div className="header-2__top ">
        <div className="custom-container">
          <div className="header-2__top--wrapper">
            <div className="header-2__top--left d-none d-md-block">
              <ul className="header-2__top--items">
                <li>
                  <a href="mailto: oksamyt.volynskii@urk.net">
                    <MailFilled />
                    <span>oksamyt.volynskii@urk.net</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+380962788588">
                    <PhoneFilled />
                    <span>+380962788588</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <HomeFilled />
                    <span>Хмельницький</span>
                  </a>
                </li>
                <li>
                  <a>
                    <span> Наші ТМ: Оксамит , Солодкій Дім , Печиво Домашнє </span>
                  </a>
                </li>

              </ul>

            </div>
            <div className="header-2__top--right">
              <ul className="header-2__top--items">
                <li>
                  {!token && (
                    <a onClick={showModal}>
                      <UserOutlined />
                      <span>Вхід</span>
                    </a>
                  )}
                  {token && (
                    <a onClick={showModal}>
                      <UserOutlined />
                      <span>Адмін панель</span>
                    </a>
                  )}
                  <Modal
                    title="Вхід"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    forceRender
                    getContainer={false}
                    footer={[]}
                  >
                    <Form
                      form={form}
                      name="login"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      style={{ maxWidth: 600 }}
                      initialValues={{ remember: false }}
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Логін"
                        name="login"
                        rules={[
                          {
                            required: true,
                            message: "Логін є обов'язковим!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Пароль є обов'язковим!",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Війти
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header main start */}
      <div className="header__main">
        <div className="custom-container-down-menu">
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Col span={6} className="header__main--logo">
              <a href="/" draggable="false">
                <img
                  draggable="false"
                  src={logo_st}
                  alt="Logo"
                  width="80"
                  height="80"
                />
              </a>
            </Col>
            <Col span={6} className="menu-wrapper " xs={12}>
              <div className="header__main--menu">
                <Menu
                    className='pc_menu'
                  onClick={onClickMenu}
                  selectedKeys={[current]}
                  mode="horizontal"
                  items={items}
                />
              </div>
              <div className="ornament"></div>
            </Col>
            <Col span={6} className="last-col-mobile">
             <Search
             className="search-header"
                onSearch={(e) => {
                  if (e.trim().length === 0) {
                    navigate(`/`);
                  } else {
                    navigate(`/search/${e}`);
                    setSearchValue("");
                  }
                }}
                placeholder="Введіть назву..."
              /> 
             
             <Button  className="mobile-menu" type="primary" onClick={showDrawer} icon={<MenuOutlined />}  />
            </Col>
          </Row>
        </div>  
      </div>
      <Drawer
        placement="top"
        onClose={onClose}
        open={open}
        // width={300}
      >
        <Menu
          items={itemsMobile}
          mode="inline"
          onClick={onClickMenu}
        />
      </Drawer>
      <Spin spinning={loading} fullscreen />
    </div>
  );
};

export default Header;
