import { createBrowserRouter } from "react-router-dom";
import Master from "../master/Master";
import Product from "../pages/product/Product";
import Products from "../pages/products/Products";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import AdminDashboard from "../admin/dashboard/AdminDashboard";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Master />,
    children: [
      {
        index: true,
        element: <Products isSearch={false}/>,
      },
      {
        path: "product/:id",
        element: <Product />,
      },
      {
        path: "category/:id",
        element: <Products isSearch={false}/>,
      },
      {
        path: "search/:id",
        element: <Products isSearch={true}/>,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: '*',
        element: <h1 style={{paddingTop:150, textAlign:"center"}}>Вибачте, сторінку, яку ви шукали, не знайдено. Можливо, вона була переміщена або видалена. Будь ласка, скористайтесь навігаційним меню або пошуком для того, щоб знайти потрібну інформацію.</h1>,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminDashboard /> ,
  }
]);

// function NotFoundPage() {
//   return <h1>404 - Page Not Found</h1>;
// }
