import { Button, Popconfirm, Spin, Table, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { ToastContainer, Zoom } from "react-toastify";
import AddProductForm from "../forms/addProduct/AddProductForm";
import { ColumnsType } from "antd/es/table";
import * as Api from "../../../../../apis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UpdateProductForm from "../forms/updateProduct/UpdateProductForm";
import { format } from "date-fns";


const ProductAdmin = () => {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();
      
    const [products, setProducts] = useState<Api.Product[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedPrductId, setSelectedProductId] = useState<number>(0);
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

    const columns: ColumnsType<Api.Product> = [
        {
            title: "Ім'я",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <span key={`product-${record.id}`}>{text}</span>
            ),
        },
        {
            title: "Наявність на складі",
            dataIndex: "availability",
            key: "availability",
            render: (text, record) => (
                <span key={`availability-${record.id}`}>{text == true ? <div>В наявності</div>: <div>Відсутній</div>}</span>
            ),
        },
        {
            title: "Рейтинг",
            dataIndex: "rate",
            key: "rate",
            render: (text, record) => (
                <span key={`rate-${record.id}`}>{text}</span>
            ),
        },
        {
            title: "Дата створення",
            dataIndex: "created",
            key: "created",
            render: (text, record) => (
                <span key={`created-${record.id}`}>{format(text, 'dd/MM/yyyy HH:mm:ss')}</span>
            ),
        },
        {
            title: "Дії",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <div>
                        <Button
                            className="action-button-admin update"
                            type="primary"
                            onClick={() => {
                                setSelectedProductId(record.id);
                                showUpdateProductModal();
                            }}
                        >
                            Редагувати
                        </Button>
                        <Popconfirm
                            title="Видалити?"
                            okText = "Так"
                            cancelText = "Ні"
                            onConfirm={async () => {
                                setLoading(true);
                                await Api.removeProduct(record.id);
                                setLoading(false);
                                setProducts(
                                    products.filter(
                                        (product) => product.id !== record.id
                                    )
                                );
                            }}
                        >
                            <Button
                                className="action-button-admin"
                                type="primary"
                                danger
                            >
                                Видалити
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
        }
    ];

    useEffect (()=>{
  let debounceTimer: NodeJS.Timeout;

        setLoading(true);

        const delayedSearch = async () => {
            const prd = await Api.getAllProducts("");
            setProducts(prd);
            setLoading(false);
        };

        const debounceSearch = () => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(delayedSearch, 500);
        };

        debounceSearch();

        return () => {
            clearTimeout(debounceTimer);
        };
    },[]);

    const productsWithKeys = products.map((product) => {
        return { ...product, key: product.id };
    });

    const showAddProductModal = () => {
        setIsAddProductModalOpen(!isAddProductModalOpen);
    };

    const addProduct = (addedProduct: Api.Product) => {
        setProducts([...products, addedProduct]);
    };

    const showUpdateProductModal = () => {
        setIsUpdateProductModalOpen(!isUpdateProductModalOpen);
    };
    const updateProduct = (updateProduct: Api.Product) => {
        const productIndex = products.findIndex((product) => product.id === updateProduct.id);
        if (productIndex !== -1) {
            const updatedProducts = [...products];
            updatedProducts[productIndex] = updateProduct;
            setProducts(updatedProducts);
        }
    }
    return(
        <Content style={{ padding: '0 48px' }}>
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
       
          <div className="App" style = {{ paddingBottom:60}}>
      
      <div className="header-wrapper">
          <div className="top-button-wrapper">
          <Button
                          className="header-button"
                          type="primary"
                          onClick={showAddProductModal}
                      >
                         Додати продукт
                      </Button>
              </div>
            
              </div>
              <Table columns={columns} dataSource={productsWithKeys} />
              {isAddProductModalOpen && (
                  <AddProductForm
                      isModalOpen={isAddProductModalOpen}
                      onShow={() => {
                          showAddProductModal();
                      }}
                      addedProduct={addProduct}
                  />
              )}
                   {isUpdateProductModalOpen && (
                  <UpdateProductForm
                      isModalOpen={isUpdateProductModalOpen}
                      onShow={() => {
                          showUpdateProductModal();
                      }}
                      productId={selectedPrductId}
                      updatedProduct={updateProduct}
                  />
              )}
              </div>
        </div>
        <Spin spinning={loading} fullscreen />
      </Content>
    )
}

export default  ProductAdmin;