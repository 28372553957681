import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import {router} from "./components/application/routes/Routes";
import { ToastContainer, Zoom } from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
          <ToastContainer
                  position={"top-right"}
                  autoClose={1500}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick={true}
                  rtl={false}
                  pauseOnFocusLoss={true}
                  draggable={true}
                  pauseOnHover={true}
                  closeButton={false}
                  transition={Zoom}
                  icon={false}
              />
   <RouterProvider router={router} />
   
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
