import { baseUrl, getOrThrow, postWithoutRequest, postWithResponse, postWithResponseFormData, remove } from "./helpers/api";
import { toastError, toastSuccess } from "./helpers/toast";
import { AxiosError } from "axios";


export async function getAllProducts(searchQuery: string, categoryId: number = 0): Promise<Product[]> {
    try {
    const result = await getOrThrow(`${baseUrl}/product/all?searchQuery=${searchQuery}&categoryId=${categoryId}`);
    return result.data as Product[];
} catch (error) {
    if (error instanceof AxiosError && error.response) {
        toastError(error.response.data);
    }
    throw error;
}
}

export async function getAllCategory(): Promise<Category[]> {
    try {
        const result = await getOrThrow(`${baseUrl}/category/all`);
        return result.data as Category[];
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}

export async function addProduct(product: ProductAddRequest, imageFiles: File[]) {
    try {
        const formData = new FormData();
        console.log(imageFiles)
        formData.append('Availability', product.availability.toString());
        formData.append('Description', product.description);
        formData.append('Name', product.name);
        formData.append('Rate', product.rate.toString());
        formData.append('ShelfLife', product.shelfLife);
        formData.append('Weight', product.weight);

        product.categoriesId.map((cat, index) => {
            formData.append(`CategoriesId`, cat.toString());
        });
        if (imageFiles) {
            imageFiles.map((file, index) => {
                formData.append(`logoImages`, file);
            });
        }

        const url = `${baseUrl}/product/add`;
        const result = (await postWithResponseFormData(url, formData)).data as Product;
        toastSuccess("Продукт доданий успішно!");
        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}

export async function loginUser(login: string, password: string) {
    const url = `${baseUrl}/login`;
    try {
        const response = await postWithResponse(url, {login, password});
        if (response.status === 401) {
            toastError("Недостатньо прав для доступу або невірний логін/пароль.");
            return null;
        }
        else{
            const responseData = response.data as LoginResponse;
            if (responseData && responseData.token) {
                const token = responseData.token;
                localStorage.setItem('token', token);
                return token;
            } else {
                toastError("Отримано неправильний формат даних від сервера");
            }
        }
        return response;
       
    } catch (error) {
        throw new Error("Сталася помилка під час обробки запиту: " + error);
    }
}

export async function addCategory(category: CategoryAddRequest) {
    try {
        const url = `${baseUrl}/category/add`;
        const result = (await postWithResponse(url, category)).data as Category;
        toastSuccess("Категорія додана успішно!");
        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}

export async function getByIdProduct(id: number): Promise<Product> {
    try {
    const result = await getOrThrow(`${baseUrl}/product/get?id=${id}`);
    return result.data as Product;
} catch (error) {
    if (error instanceof AxiosError && error.response) {
        toastError(error.response.data);
    }
    throw error;
}
}

export async function updateProduct(product: ProductUpdateRequest, imageFiles: File[]) {
    try {
        const formData = new FormData();
        console.log(imageFiles)
        formData.append('Availability', product.availability.toString());
        formData.append('Description', product.description);
        formData.append('Name', product.name);
        formData.append('Rate', product.rate.toString());
        formData.append('ShelfLife', product.shelfLife);
        formData.append('Weight', product.weight);
        formData.append('Id', product.id.toString());

        product.categoriesId.map((cat, index) => {
            formData.append(`CategoriesId`, cat.toString());
        });
        if (product.oldImagesName) {
            console.log(product.oldImagesName);
            product.oldImagesName.map((cat, index) => {
                formData.append(`OldImagesName`, cat.toString());
            });
        }
        else {
            product.oldImagesName = [];
            formData.append(`OldImagesName`, '');
            console.log(product.oldImagesName);
        }


        if (imageFiles) {
            imageFiles.map((file, index) => {
                formData.append(`logoImages`, file);
            });
        }
        const url = `${baseUrl}/product/update`;
        const result = (await postWithResponseFormData(url, formData)).data as Product;
        toastSuccess("Продукт оновлений успішно!");
        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}

export interface LoginResponse {
    token: string;
}

export async function removeProduct(bookId: number) {
    try {
        await remove(`${baseUrl}/product/delete?id=${bookId}`);
        toastSuccess("Продукт видалено успішно!");
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}

export async function removeCategory(bookId: number) {
    try {
        await remove(`${baseUrl}/category/delete?id=${bookId}`);
        toastSuccess("Категорія видалена успішно!");
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            toastError(error.response.data);
        }
        throw error;
    }
}
export interface ProductAddRequest {
    name: string;
    description: string;
    rate: number;
    weight: string
    shelfLife: string;
    availability: boolean;
    categoriesId: number[];
}

export interface CategoryAddRequest {
    name: string;
    rate: number;
}

export interface ProductUpdateRequest {
    id: number;
    name: string;
    description: string;
    rate: number;
    weight: string
    shelfLife: string;
    availability: boolean;
    categoriesId: number[];
    oldImagesName: string[]
}

export interface Product {
    id: number;
    name: string;
    description: string;
    created: string;
    rate: number;
    weight: string
    shelfLife: string;
    availability: boolean;
    categories: Category[];
    images: ProductImages[];
}

export interface ProductImages {
    id: number;
    productId: number,
    imagePath: string
}

export interface ProductCategories {
    id: number;
    productId: number,
    categoryId: string
}

export interface Category {
    id: number;
    name: string;
    rate: number;
    created: string;
}
