import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { ConfigProvider, Spin } from "antd";
import { useEffect, useState } from "react";
import * as Api from "../../../apis";
import { LoadingProvider, useLoading } from "../../context/LoadingContext";

const Master = () => {
  const [categories, setCaregories] = useState<Api.Category[]>([]);
  const { loading } = useLoading();
  
  useEffect (()=>{
              const delayed= async () => {
                var resultCategories = await Api.getAllCategory();
                setCaregories(resultCategories);
            };
            delayed();
      },[]);

  return (
    <div>
        <ConfigProvider
                  theme={{
                    token:{
                        colorPrimary: '#d68d67',
                        colorPrimaryActive: '#d68d67',
                        colorPrimaryHover: '#d68d67',
                        fontFamily: 'Andika'
                    },
                    components: {
                      Menu: {
                        horizontalItemSelectedColor: "none",
                        colorSplit: "none",
                        horizontalItemHoverColor: '#d68d67',
                        itemSelectedColor: '#d68d67'
                      },
                    
                    },
                  }}
                >
                  <header>
        <Header />
      </header>
      <main>
      <Outlet />
      </main>
      <footer>
        {categories.length > 0 && !loading &&
         <Footer categories= {categories} /> 
        }
      </footer>
      </ConfigProvider>
      <Spin spinning={loading} fullscreen />
    </div>
  );
};

const MasterWithProvider = () => (
  <LoadingProvider>
    <Master />
  </LoadingProvider>
);

export default MasterWithProvider;
