import './contact.scss';
const Contact = () => {

    return (

        <div className='about-container' style={{display: "flex", justifyContent: "center"}}>
            <div style={{width: 1320, marginTop: 40}}>
                <div style={{fontFamily: 'Arial, sans-serif', margin: 0, padding: 0}}>
                    <div style={{
                        maxWidth: '800px',
                        margin: '50px auto',
                        padding: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                    }}>

                        <div style={{marginTop: 20, textAlign: 'center'}}>
                            <h1>ОКСАМИТ ВОЛИНСЬКИЙ</h1>
                            <h2>Контактна інформація:</h2>
                            <p style={{margin: '5px 0'}}>Телефон: +380962788588</p>
                            <p style={{margin: '5px 0'}}>Email: oksamyt.volynskii@urk.net</p>
                        </div>
                        <div style={{marginTop: '20px', textAlign: 'center'}}>
                            <h2>Години роботи:</h2>
                            <p style={{margin: '5px 0'}}>Понеділок - П'ятниця: 8:00 - 18:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
