import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio, Select, Spin, Upload, UploadFile, UploadProps } from "antd";
import * as Api from "../../../../../../apis";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { baseUrl, baseUrlImg } from "../../../../../../helpers/api";

const UpdateProductForm = ({
    isModalOpen,
    productId,
    onShow,
    updatedProduct
}: {
    isModalOpen: boolean;
    productId: number;
    onShow: () => void;
    updatedProduct: (value: Api.Product) => void;
}) => {
    const [fileList, setFileList] = useState<UploadFile[]>([ ]);


    const [form] = Form.useForm();
    const { Option } = Select;
    const [product, setProduct] = useState<Api.Product>();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Api.Category[]>([]);
    useEffect(() => {
        (async () => {
            let timeoutId;
            if (isModalOpen) {
                timeoutId = setTimeout(() => {
                    setLoading(true);
                }, 500);
            }
        
            const category = await Api.getAllCategory();
            
            setCategories(category);
            setLoading(false);
            clearTimeout(timeoutId);
        })();
    }, []);
    useEffect(() => {
        (async () => {
            let timeoutId;
            if (isModalOpen) {
                timeoutId = setTimeout(() => {
                    setLoading(true);
                }, 500);
            }
        
            const pruductResponse: Api.Product = await Api.getByIdProduct(productId);
            
            setProduct(pruductResponse);

            setFileList( pruductResponse.images.map((image) => ({
                uid: image.id.toString(),
                name: `image_${image.id}.png`, 
                status: 'done',
                url: baseUrlImg + image.imagePath
            })))
            setLoading(false);
            clearTimeout(timeoutId);
        })();
    }, [productId, isModalOpen]);

    useEffect(() => {
        if (product)
            form.setFieldsValue({
                name: product.name,
                description: product.description,
                rate: product.rate,
                weight: product.weight,
                shelfLife : product.shelfLife,
                availability: product.availability,
                categoriesId: product.categories.map(c => c.id),
            });
    }, [product, form]);


    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

    let modifiedUrls: string[] = fileList.slice().map(obj => {
        return obj.url?.replace(baseUrlImg, "");
    }).filter(url => url !== undefined) as string[];

    const onFinish = async (values: Api.ProductUpdateRequest) => {
       setLoading(true);
        const product: Api.ProductUpdateRequest = {
            id: productId,
            name: values.name,
            description: values.description,
            rate: values.rate,
            weight: values.weight,
            shelfLife : values.shelfLife,
            availability: values.availability,
            categoriesId: values.categoriesId,
            oldImagesName: modifiedUrls
        };

        const files =  fileList.filter(originFileObj => originFileObj !== undefined).map((value: any) => {
            return value.originFileObj; 
          });

          console.log(fileList);
          console.log(files);
        const productResponse = await Api.updateProduct(product, files);
        updatedProduct(productResponse);
        setLoading(false);
        onShow();
    };

    return(  <Modal
        forceRender
        getContainer={false}
        title="Редагувати продукт"
        open={isModalOpen}
        onCancel={onShow}
        footer={[]}
    >
          <Spin spinning={loading} fullscreen />
        <Form
            form={form}
            name="UpdateGenreForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
        >
             <Form.Item
                label="Ім'я"
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Ім'я є обов'язковим!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Опис"
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Опис є обов'язковим!"
                    },
                ]}
            >
                 <TextArea rows={4} />
            </Form.Item>
            <Form.Item
                label="Вага"
                name="weight"
                rules={[
                    {
                        required: true,
                        message: "Вага є обов'язковою!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Термін придатності"
                name="shelfLife"
                rules={[
                    {
                        required: true,
                        message: "Термін придатності є обов'язковим!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
           
        <Form.Item label="Рейтинг" name="rate">
          <InputNumber />
        </Form.Item>
        <Form.Item
      name="categoriesId"
      label="Категорія"
      rules={[{ required: true, message: 'Додайте категорію!', type: 'array' }]}
    >
      <Select mode="multiple" placeholder="Оберіть категорії">
      {categories.map(item => (
        <Option key={item.name} value={item.id}>
          {item.name}
        </Option>
      ))}
      </Select>
    </Form.Item>
        <Form.Item label="Зображення" valuePropName="fileList" >
          <Upload 
          fileList={fileList}
          onChange={handleChange}
          action="/upload.do" listType="picture-card">
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Завантажити</div>
            </button>
          </Upload>
        </Form.Item>
        <Form.Item label="На складі" name="availability">
          <Radio.Group>
            <Radio value={true} > В наявності </Radio>
            <Radio value={false}> Відсутній </Radio>
          </Radio.Group>
        </Form.Item>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }} style={{marginRight: 25}}>
                <Button type="primary" htmlType="submit" >
                    Редагувати
                </Button>
            </Form.Item>
        </Form>
      
    </Modal>)
}

export default UpdateProductForm;
