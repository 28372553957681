import './about.scss';
const About = () => {

    return(
        <div className='about-container'  style={{ display: "flex", justifyContent: "center" }}>
            <div  style={{ width: 1320, marginTop: 40 }}>
                <div style={{ fontFamily: 'Arial, sans-serif',  margin: 0, padding: 0 }}>
                    <div style={{ maxWidth: '800px', margin: '50px auto', padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                        <h1 style={{ color: '#333', textAlign: 'center' }}>Про нас</h1>
                        <p style={{ color: '#666', fontSize: '18px', lineHeight: '1.6', textAlign: 'justify' }}>ТМ «Оксамит» є виробником класичних
                            кондитерських виробів, що зберігають в собі найкращі традиції і якість напрацьовану роками. Родина ТМ «Оксамит» представлена в восьми
                            областях України. Забезпечуємо швидку доставку високоякісних кондитерських виробів. Для постійного зростання торгівля ми регулярно пропонуємо різноманітні акції.
                            ТМ «Оксамит» з повагою ставиться до кожного
                            клієнта та має індивідуальний підхід.
                        </p>

                        <h2 style={{ color: '#333', marginTop: '30px' }}>Наші цінності</h2>
                        <ul style={{ color: '#666', fontSize: '18px', lineHeight: '1.6', paddingLeft: '20px' }}>
                            <li>Якість - ми завжди стежимо за якістю наших продуктів.</li>
                            <li>Смак - ми прагнемо досягти ідеального смаку у всіх наших рецептах.</li>
                            <li>Відповідальність - ми відповідально ставимося до виробництва та споживання продукції.</li>
                            <li>Інновації - ми постійно вдосконалюємо свої технології та рецепти, щоб задовольнити потреби наших клієнтів.</li>
                        </ul>

                        <h2 style={{ color: '#333', marginTop: '30px' }}>Наші партнери</h2>
                        <p style={{ color: '#666', fontSize: '18px', lineHeight: '1.6' }}>Ми співпрацюємо з провідними дистриб'юторами, щоб постійно розширювати свій асортимент та підвищувати якість наших продуктів.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
