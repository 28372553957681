import { Card, Col, Row, Spin } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate, useParams } from "react-router-dom";
import "./products.scss";
import img1 from "../../../../assets/images/img1.png";
import { useEffect, useState } from "react";
import * as Api from "../../../../apis";
import { baseUrlImg } from "../../../../helpers/api";
import { useLoading } from "../../../context/LoadingContext";

export interface IsSearchProps{
  isSearch : boolean;
}


const Products : React.FC<IsSearchProps> = ({ isSearch }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState<Api.Product[]>([]);
  const { loading, setLoading } = useLoading();
  const [categories, setCaregories] = useState<Api.Category[]>([]);

  useEffect (()=>{
    const delayed= async () => {
      var resultCategories = await Api.getAllCategory();
      setCaregories(resultCategories);
  };
  delayed();
},[]);

  useEffect (()=>{
    let debounceTimer: NodeJS.Timeout;
  
          setLoading(true);
  
          const delayedSearch = async () => {
            if(id && isSearch === false) {
              const prd = await Api.getAllProducts("",Number(id));
              setProducts(prd);
            }
            else if(id){
              const prd = await Api.getAllProducts(id);
              setProducts(prd);
            }
            else {
              const prd = await Api.getAllProducts("");
              setProducts(prd);
            }
             
              setLoading(false);
          };
  
          const debounceSearch = () => {
              clearTimeout(debounceTimer);
              debounceTimer = setTimeout(delayedSearch, 500);
          };
  
          debounceSearch();
  
          return () => {
              clearTimeout(debounceTimer);
          };
      },[id]);
  

  const handleClick = (id: string) => {
    navigate(`/product/${id}`);
  };

  const handleClickCategory = (id: number) => {
    navigate(`/category/${id}`);
  };

  
  return (
    <div className="custom-container custom-container-products">
      <Row justify={'center'}>
        <Col span={20}>
            <Row>
                {products.length === 0 && !loading ? (
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <h1>Товари відсутні</h1>
                    </div>
                ) : (
                    products.map((item) => (
                        <Col
                            key={item.id}
                            xs={24}
                            sm={12}
                            md={6}
                            style={{ display: "flex", justifyContent: 'center' }}
                            className='products-container'
                        >
                            <Card
                                className="card-wrapper"
                                onClick={() => handleClick(item.id.toString())}
                                hoverable
                                cover={
                                    <div className="img-card-container">
                                        {item.images && item.images.length > 0 ? (
                                            <img alt="example" className="img-card" src={baseUrlImg + item.images[0].imagePath} />
                                        ) : (
                                            <p>No images available</p>
                                        )}
                                    </div>
                                }
                            >
                                <Meta
                                    title={item.name}
                                    description={item.categories.map(category => category.name).join(', ')}
                                />
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
        </Col>
        <Col span={4} className="category-wrapper">
            <div className='category-wrapper-border'>
        <a className="category-wrapper-text" onClick={()=>{ navigate(`/`);}}>Продукція</a>
          {categories.length !== 0 ?
           <ul>
           {categories.map((category: Api.Category, index: number) => {
                     return (
                       <li  key={index}>
                         <a className="cat-text"onClick={()=>{handleClickCategory(category.id)}}>{category.name}</a>
                       </li>
                     );
                   })}
           </ul>
           :  <div className="spin-wrapper">
           <Spin />
         </div>}
            </div>
        </Col>
      </Row>
      <Spin spinning={loading} fullscreen />
    </div>
  );
};
export default Products;
