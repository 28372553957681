import { Card, Carousel, Col, Divider, Image, Row, Spin } from "antd";
import Meta from "antd/es/card/Meta";
import * as Api from "../../../../apis";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseUrlImg } from "../../../../helpers/api";
import { useLoading } from "../../../context/LoadingContext";
import "./product.scss";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<Api.Product>();
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;

    setLoading(true);
    const delayedSearch = async () => {
      const prd = await Api.getByIdProduct(Number(id));
      setProduct(prd);
      setLoading(false);
    };

    const debounceSearch = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(delayedSearch, 500);
    };

    debounceSearch();

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [id, setLoading]);

  return (
      <div style={{ justifyContent: "center"}} className='product-main-wrapper'>
        {!loading && product && (
            <div>
              <Row className="row-product-wrapper">
                <Col xs={24} md={12}>
                  <Carousel arrows autoplay className="carusel-wrapper">
                    {product.images.map((item) => (
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems:'center' }}>
                          <Image style={{ height: "auto" , borderRadius: "15px"}} src={baseUrlImg + item.imagePath} />
                        </div>
                    ))}
                  </Carousel>
                </Col>

                <Col xs={24} md={12} style={{ paddingLeft: 20 }}>
                  <h1>{product?.name}</h1>
                  <div style={{ fontSize: 18 }}>
                    {product?.availability ? (
                        <>На складі</>
                    ) : (
                        <>Відсутній на складі</>
                    )}
                    <Divider />
                    {product?.description}
                    <br />
                    <br />
                    <b>Вага:</b> {product?.weight}
                    <br />
                    <b>Термін зберігання:</b> {product?.shelfLife}
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 40 }}>
                <Col span={24} style={{ fontSize: 18, paddingLeft: 20, paddingBottom: 20 }}>
                  <h2>Чому нас обирають</h2>
                  <p>
                    ТМ "Оксамит" є виробником класичних кондитерських виробів,
                    що зберігають в собі найкращі традиції і якість напрацьовану роками.
                    Родина ТМ "Оксамит" представлена в восьми областях України.
                    Забезпечуємо швидку доставку високоякісних кондитерських виробів.
                    Для постійного зростання ми регулярно пропонуємо різноманітні акції.
                    ТМ "Оксамит" з повагою ставиться до кожного клієнта та має індивідуальний підхід.
                  </p>
                </Col>
              </Row>
            </div>
        )}
        <Spin spinning={loading} fullscreen />
      </div>
  );
};

export default Product;
