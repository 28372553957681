import { Button, Popconfirm, Spin, Table, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import * as Api from "../../../../../apis";
import { ColumnsType } from "antd/es/table";
import AddCategoryForm from "../forms/addCategory/AddCategoryForm";
import { format } from "date-fns";

const CategoryAdmin = () => {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState<Api.Category[]>([]);
      const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
      const columns: ColumnsType<Api.Category> = [
        {
            title: "Ім'я",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <span key={`product-${record.id}`}>{text}</span>
            ),
        },
        {
            title: "Рейтинг",
            dataIndex: "rate",
            key: "rate",
            render: (text, record) => (
                <span key={`rate-${record.id}`}>{text}</span>
            ),
        },
        {
            title: "Дата створення",
            dataIndex: "created",
            key: "created",
            render: (text, record) => (
                <span key={`created-${record.id}`}>{format(text, 'dd/MM/yyyy HH:mm:ss')}</span>
            ),
        },
        {
            title: "Дії",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <div>
                        <Popconfirm
                            title="Увага! Ця категорія буде видалина зі всіх продуктів до яких прив'язана. Видалити?"
                            okText = "Так"
                            cancelText = "Ні"
                            onConfirm={async () => {
                                 setLoading(true);
                                 await Api.removeCategory(record.id);
                                setLoading(false);
                                 setCategories(
                                     categories.filter(
                                         (category) => category.id !== record.id
                                     )
                                 );
                            }}
                        >
                            <Button
                                className="action-button-admin"
                                type="primary"
                                danger
                            >
                                Видалити
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
        }
    ];


      useEffect (()=>{
        let debounceTimer: NodeJS.Timeout;
      
              setLoading(true);
      
              const delayedSearch = async () => {
                  const cat = await Api.getAllCategory();
                  setCategories(cat);
                  setLoading(false);
              };
      
              const debounceSearch = () => {
                  clearTimeout(debounceTimer);
                  debounceTimer = setTimeout(delayedSearch, 500);
              };
      
              debounceSearch();
      
              return () => {
                  clearTimeout(debounceTimer);
              };
          },[]);

          const categoryWithKeys = categories.map((category) => {
            return { ...category, key: category.id };
        });
        const showAddCategoryModal = () => {
            setIsAddCategoryModalOpen(!isAddCategoryModalOpen);
        };
        const addCategory = (addedCategory: Api.Category) => {
            setCategories([...categories, addedCategory]);
        };
    
          
    return(  
    <Content style={{ padding: '0 48px' }}>
 <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
       
          <div className="App" style = {{ paddingBottom:60}}>
            <ToastContainer
                  position={"top-right"}
                  autoClose={1500}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick={true}
                  rtl={false}
                  pauseOnFocusLoss={true}
                  draggable={true}
                  pauseOnHover={true}
                  closeButton={false}
                  transition={Zoom}
                  icon={false}
              />
      <div className="header-wrapper">
          <div className="top-button-wrapper">
          <Button
                          className="header-button"
                          type="primary"
                           onClick={showAddCategoryModal}
                      >
                         Додати категорію
                      </Button>
          </div>
          </div>
          <Table columns={columns} dataSource={categoryWithKeys} />
          </div>
          </div>
          {isAddCategoryModalOpen && (
                  <AddCategoryForm
                      isModalOpen={isAddCategoryModalOpen}
                      onShow={() => {
                          showAddCategoryModal();
                      }}
                      addedCategory={addCategory}
                  />
              )}
          <Spin spinning={loading} fullscreen />
    </Content>)
}

export default  CategoryAdmin;