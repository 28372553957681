import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio, Select, Spin, Upload } from "antd";
import * as Api from "../../../../../../apis";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
const AddProductForm = ({
    isModalOpen,
    onShow,
    addedProduct
}: {
    isModalOpen: boolean;
    onShow: () => void;
    addedProduct: (value: Api.Product) => void;
}) => {
    const category = [
        { name: 'var3' },
        { name: 'var2' }    
      ];
    const { Option } = Select;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Api.Category[]>([]);
    useEffect(() => {
        (async () => {
            let timeoutId;
            if (isModalOpen) {
                timeoutId = setTimeout(() => {
                    setLoading(true);
                }, 500);
            }
        
            const category = await Api.getAllCategory();
            
            setCategories(category);
            setLoading(false);
            clearTimeout(timeoutId);
        })();
    }, []);

    const onFinish = async (values: { name: string, description: string, rate: number, weight: string, shelfLife: string, availability: boolean, categoriesId: number[], fileList: any}) => {
        setLoading(true);
       
        const product: Api.ProductAddRequest = {
            name: values.name,
            description: values.description,
            rate: values.rate || 0,
            weight: values.weight,
            shelfLife : values.shelfLife,
            availability: values.availability,
            categoriesId: values.categoriesId,
        };
     let files: any = null;
     if(values.fileList){
        files =  values.fileList.map((value: any) => {
            return value.originFileObj; 
          });
     }
       const response =  await Api.addProduct(product,files);
        addedProduct(response);
        setLoading(false);
        onShow();
    };

    useEffect(() => {
            form.setFieldsValue({
                availability: false
            });
    });

    return (        <Modal
        forceRender
        getContainer={false}
        title="Додати продукт"
        open={isModalOpen}
        onCancel={onShow}
        footer={[]}
    >
        <Form
            form={form}
            name="AddProductForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Ім'я"
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Ім'я є обов'язковим!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Опис"
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Опис є обов'язковим!"
                    },
                ]}
            >
                 <TextArea rows={4} />
            </Form.Item>
            <Form.Item
                label="Вага"
                name="weight"
                rules={[
                    {
                        required: true,
                        message: "Вага є обов'язковою!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Термін придатності"
                name="shelfLife"
                rules={[
                    {
                        required: true,
                        message: "Термін придатності є обов'язковим!"
                    },
                ]}
            >
                <Input />
            </Form.Item>
           
        <Form.Item label="Рейтинг" valuePropName="rate" name="rate">
          <InputNumber defaultValue={0} />
        </Form.Item>
        <Form.Item
      name="categoriesId"
      label="Категорія"
      rules={[{ required: true, message: 'Додайте категорію!', type: 'array' }]}
    >
      <Select mode="multiple" placeholder="Оберіть категорії">
      {categories.map(item => (
        <Option key={item.name} value={item.id}>
          {item.name}
        </Option>
      ))}
      </Select>
    </Form.Item>
        <Form.Item getValueFromEvent={(event) =>{
            return event?.fileList;
        }} label="Зображення" valuePropName="fileList" name="fileList">
          <Upload listType="picture-card" accept="image/*">
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Завантажити</div>
            </button>
          </Upload>
        </Form.Item>
        <Form.Item label="На складі" name="availability">
          <Radio.Group>
            <Radio value={true} > В наявності </Radio>
            <Radio value={false}> Відсутній </Radio>
          </Radio.Group>
        </Form.Item>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Додати
                </Button>
            </Form.Item>
        </Form>
        <Spin spinning={loading} fullscreen />
    </Modal>);
}

export default AddProductForm;